import { SwipeDirection, useSwipe } from '@vueuse/core'
import { articleDetailPath } from '@qcwp/common'
import type { ArticlePagesResponse } from '~~/src/server/modules/article/type'
import type { ARTICLE_LIST_TYPE, JumpArticle } from '~~/src/store'
import { useJumpArticleStore } from '~~/src/store'

export function useJump(id: string) {
  const { isMobileOrTablet } = useDevice()
  const router = useRouter()
  const jumpArticleStore = useJumpArticleStore()
  jumpArticleStore.changeCurrentArticleId(id)

  const targetRef = ref<HTMLElement | null>(null)
  const containerRef = ref<HTMLElement | null>(null)
  const containerWidth = computed(() => containerRef.value?.offsetWidth)
  const style = ref({})
  const tips = ref({ type: 'next', id: '', title: '' })
  const directionInclude: (SwipeDirection | null)[] = jumpArticleStore.getCanJumpDirection()
  const { pre, next } = jumpArticleStore.getJumpId()

  let isSwipeEffectiveEl = false
  const { direction, isSwiping, lengthX, lengthY } = (jumpArticleStore.canJump(id) && isMobileOrTablet)
    ? useSwipe(targetRef, { passive: true, threshold: 50, onSwipeStart, onSwipe, onSwipeEnd })
    : { direction: ref(SwipeDirection.NONE), isSwiping: ref(false), lengthX: ref(0), lengthY: ref(0) }

  function reset() {
    style.value = {}
  }

  function onSwipeStart(_e: TouchEvent) {
    if (!checkCanJump(_e.target as HTMLElement))
      isSwipeEffectiveEl = false
    else
      isSwipeEffectiveEl = true
  }
  function onSwipe(_e: TouchEvent) {
    if (!isSwipeEffectiveEl)
      return
    if (containerWidth.value && directionInclude.includes(direction.value)) {
      if (lengthX.value > 50 && direction.value === SwipeDirection.LEFT) {
        const length = Math.abs(lengthX.value)
        style.value = {
          opacity: 0.8 + length / containerWidth.value,
          right: `${Math.min(length / 1.2 - 90, 0)}px`,
        }
        tips.value = { type: 'next', id: next?.id, title: next?.title }
        // console.log('next', length, containerWidth.value, next, style)
      }
      else if (lengthX.value < -50 && direction.value === SwipeDirection.RIGHT) {
        const length = Math.abs(lengthX.value)
        style.value = {
          opacity: 0.8 + length / containerWidth.value,
          left: `${Math.min(length / 1.2 - 90, 0)}px`,
        }
        tips.value = { type: 'pre', id: pre?.id, title: pre?.title }
        // console.log('pre', length, containerWidth.value, next, style)
      }
      else {
        style.value = {}
      }
    }
  }
  function onSwipeEnd(_e: TouchEvent, _direction: SwipeDirection) {
    if (!isSwipeEffectiveEl)
      return
    let item
    if (!directionInclude.includes(direction.value)) {
      style.value = {}
      return
    }

    if (lengthX.value > 50 && Math.abs(lengthX.value / 1.2) >= 90)
      item = next
    else if (lengthX.value < -50 && Math.abs(lengthX.value / 1.2) >= 90)
      item = pre

    style.value = {}
    item && jump(item)
  }
  function jump(item: JumpArticle) {
    const url = articleDetailPath(item.type, { id: item.id, linkUrl: item.linkUrl })
    if (!url.includes('http'))
      router.push(url)
    else
      window.location.href = url
  }
  return {
    targetRef,
    containerRef,
    reset,

    direction,
    isSwiping,
    lengthX,
    lengthY,
    style,
    tips,
  }
}

export function formatJumpLocation(data: {
  index: number
  article: ArticlePagesResponse
  params: any
  list: any[]
  pageSize: number
  type: ARTICLE_LIST_TYPE
}) {
  const { index, article, params, list, pageSize, type } = data
  const startIndex = Math.floor(index / pageSize) * pageSize
  return {
    articleId: article.id,
    currentPage: Math.floor(index / pageSize) + 1,
    currentPageIndex: index % pageSize,
    pageSize,
    type,
    params,
    list: list.slice(startIndex, startIndex + pageSize),
  }
}

// 判断当前元素和其父元素是否包含data-jump属性
function checkCanJump(el: HTMLElement): boolean {
  if (el?.dataset?.jump)
    return false

  return el.parentElement ? checkCanJump(el.parentElement) : true
}
